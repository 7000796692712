import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Link, } from "react-router-dom";
import './App.css';
import HeaderBar from "../HeaderBar/HeaderBar";
import Dashboard from '../Dashboard/Dashboard';
import Login from '../Login/Login';
import Preferences from '../Preferences/Preferences';
import useToken from './useToken';

function App() {

	const { token, setToken } = useToken();

	if(!token) {
		return <Login setToken={setToken} />
	}

	return (
	<div>
	  <BrowserRouter>
		<HeaderBar />
		<Routes>
			<Route path="/dashboard" element={<Dashboard />} />
			<Route path="/preferences" element={<Preferences />} />
		</Routes>
	  </BrowserRouter>
	</div>
	);
}
export default App;